import React, { FC } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { breakpoints } from "@theme/breakpoints";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { easeOutExpo } from "@theme/easing";
import { useIsDarkMode } from "@hooks/useIsDarkMode";
import { SeeMoreButton } from "@components/atoms/SeeMoreButton";

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1050px;
  margin: auto;
`;

const StyledInnerWrapper = styled(motion.div)<{ $reversed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $reversed }) =>
    $reversed &&
    css`
      flex-direction: row-reverse;
    `}

  ${breakpoints.phoneMax`
    flex-direction: column;
  `}
`;

const StyledImageWrapper = styled.div<{ $reversed: boolean }>`
  position: relative;
  width: 500px;
  height: 350px;
  margin: 0 100px 0 0;

  img,
  video {
    object-fit: contain;
  }

  video {
    width: 100%;
    height: 100%;
  }

  ${breakpoints.laptopMax`
    width: 350px;
    height: 250px;
    margin: 0 80px 0 0;
  `}

  ${breakpoints.tabletMax`
    margin: 0 40px 0 0;
  `}

  ${({ $reversed }) =>
    $reversed &&
    css`
      margin: 0 0 0 100px;

      ${breakpoints.laptopMax`
        margin: 0 0 0 80px;
      `}

      ${breakpoints.tabletMax`
        margin: 0 0 0 40px;
      `}
    `}

  
  ${breakpoints.phoneMax`
    margin: 0 0 40px;
    width: 200px;
    height: 180px;
  `}
`;

const StyledRightWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 460px;
  line-height: 1.2;

  ${breakpoints.laptopMax`
    max-width: 400px;
  `}

  ${breakpoints.phoneMax`
    text-align: center;
    align-items: center;
  `}
`;

const StyledHeadline = styled.h3`
  margin: 5px 0 20px;
  font-size: 3.6rem;
  font-weight: 700;

  span {
    color: ${({ theme }) => theme.primary};
  }

  ${breakpoints.laptopMax`
    font-size: 2.4rem;
    margin: 5px 0 15px;
  `}

  ${breakpoints.phoneMax`
    font-size: 2rem;
    text-align: center;
  `}
`;

const StyledSubHeadline = styled.h4`
  order: -1;
  margin: 0;
  font-weight: 600;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.washRegular};
  line-height: 1.5;
  word-break: keep-all;

  ${breakpoints.laptopMax`
    font-size: 1.6rem;
  `}

  ${breakpoints.phoneMax`
    font-size: 1.4rem;
    text-align: center;
  `}
`;

const StyledParagraph = styled.p`
  line-height: 1.5;
  margin: 0;
  font-size: 1.8rem;

  ${breakpoints.laptopMax`
    font-size: 1.6rem;
  `}

  ${breakpoints.phoneMax`
    text-align: center;

  `}
`;

const StyledSeeMoreButton = styled(SeeMoreButton)`
  margin-left: auto;
  margin-top: 10px;

  ${breakpoints.phoneMax`
    margin: 10px auto 0;
  `}
`;

export const Service: FC<Props> = ({
  title,
  subtitle,
  description,
  image,
  reversed,
  className,
  video,
  seeMore,
}) => {
  const { ref, inView } = useInView({ threshold: 0.6 });
  const isDarkMode = useIsDarkMode();

  return (
    <StyledWrapper className={className} ref={ref}>
      <StyledInnerWrapper
        $reversed={reversed}
        initial={{ y: 50, scale: 1.2, opacity: 0 }}
        animate={inView && { y: 0, scale: 1, opacity: 1 }}
        transition={{ ease: easeOutExpo, duration: 1.5 }}
      >
        <StyledImageWrapper $reversed={reversed}>
          {image && <GatsbyImage image={getImage(image)} alt="" />}
          {video && (
            <video
              src={video.replace("*", isDarkMode ? "dark" : "light")}
              autoPlay
              muted
              loop
              playsInline
            />
          )}
        </StyledImageWrapper>
        <StyledRightWrapper>
          <StyledHeadline dangerouslySetInnerHTML={{ __html: title }} />
          <StyledSubHeadline>{subtitle}</StyledSubHeadline>
          <StyledParagraph>{description}</StyledParagraph>
          {seeMore && (
            <StyledSeeMoreButton to={seeMore}>
              Zobacz więcej
            </StyledSeeMoreButton>
          )}
        </StyledRightWrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

interface Props {
  title: string;
  subtitle: string;
  description: string;
  image?: ImageDataLike;
  video?: any;
  reversed: boolean;
  className?: string;
  seeMore?: string;
}
