import React from "react";
import styled from "styled-components";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { stylesWrapper } from "@theme/commonStyles";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { breakpoints } from "@theme/breakpoints";
import { ReactComponent as reactSvg } from "@assets/images/technologies/react.svg";
import { ReactComponent as gatsbySvg } from "@assets/images/technologies/gatsby.svg";
import { ReactComponent as nextjsSvg } from "@assets/images/technologies/nextjs.svg";
import { ReactComponent as svelteSvg } from "@assets/images/technologies/svelte.svg";
import { ReactComponent as directusSvg } from "@assets/images/technologies/directus.svg";
import { ReactComponent as dsMaxSvg } from "@assets/images/technologies/3dsmax.svg";
import { ReactComponent as blenderSvg } from "@assets/images/technologies/blender.svg";
import { ReactComponent as coreldrawSvg } from "@assets/images/technologies/coreldraw.svg";
import { ReactComponent as nodejs } from "@assets/images/technologies/nodejs.svg";
import { ReactComponent as premiereSvg } from "@assets/images/technologies/premiere.svg";
import { ReactComponent as photoshopSvg } from "@assets/images/technologies/photoshop.svg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { easeOutExpo } from "@theme/easing";

const StyledWrapper = styled.section`
  ${stylesWrapper};
  margin-top: 200px;

  ${breakpoints.laptopMax`
    margin-top: 150px;
  `};

  ${breakpoints.tabletMax`
    margin-top: 110px;
  `};

  ${breakpoints.phoneMax`
    margin-top: 90px;
  `};
`;

const StyledHeadline = styled(SectionHeadline)`
  margin: 0 0 160px;

  ${breakpoints.laptopMax`
    margin: 0 0 120px;
  `}

  ${breakpoints.tabletMax`
    margin: 0 0 90px;
  `}

  ${breakpoints.phoneMax`
    margin: 0 0 60px;
  `}
`;

const StyledIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 75px 0;
  margin-left: auto;
  margin-right: auto;

  ${breakpoints.laptopMax`
    max-width: 1150px;
    gap: 50px 0;
  `}

  ${breakpoints.tabletMax`
    gap: 45px 70px;
  `}
  
  ${breakpoints.phoneMax`
    gap: 40px;
    max-width: 470px;
  `}
`;

const StyledIconWrapper = styled(motion.div)`
  height: 100px;
  width: fit-content;
  margin: auto;

  ${breakpoints.laptopMax`
    height: 70px;
  `};

  ${breakpoints.phoneMax`
    height: 50px;
  `};
`;

const StyledIcon = styled.div`
  position: relative;
  filter: saturate(0%);
  opacity: 0.6;
  transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
  width: fit-content;
  height: 100%;

  svg {
    width: auto;
    height: 100%;
  }

  :hover {
    filter: saturate(100%);
    opacity: 1 !important;
  }
`;

const StyledBreak = styled.span`
  flex-basis: 100%;

  ${breakpoints.tabletMax`
    display: none;
  `}
`;

const TECHNOLOGIES = [
  ["React", reactSvg],
  ["Gatsby", gatsbySvg],
  ["Next.js", nextjsSvg],
  ["Svelte", svelteSvg],
  ["Directus", directusSvg],
  ["Node.js", nodejs],
  ["3ds Max", dsMaxSvg],
  ["Blender", blenderSvg],
  ["CorelDRAW", coreldrawSvg],
  ["Adobe Premiere", premiereSvg],
  ["Adobe Photoshop", photoshopSvg],
];

export const TechnologiesWeUseSection = () => {
  const { t } = useTranslation("technologies-we-use");
  const { ref, inView } = useInView({ threshold: 0.3 });

  return (
    <StyledWrapper>
      <StyledHeadline dangerouslySetInnerHTML={{ __html: t("title") }} />

      <StyledIconsWrapper ref={ref}>
        {TECHNOLOGIES.map(([name, Icon], index) => (
          <React.Fragment key={name}>
            <StyledIconWrapper
              initial={{ opacity: 0, scale: 0.6 }}
              animate={inView && { opacity: 1, scale: 1 }}
              transition={{
                duration: 1.5,
                ease: easeOutExpo,
                delay: index * 0.1,
              }}
            >
              <StyledIcon>
                <Icon />
              </StyledIcon>
            </StyledIconWrapper>
            {index === 5 && <StyledBreak />}
          </React.Fragment>
        ))}
      </StyledIconsWrapper>
    </StyledWrapper>
  );
};
