import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import gsap, { Expo } from "gsap";
import ReactHtmlParser from "react-html-parser";

const StyledWrapper = styled.span`
  position: relative;

  > span {
    position: relative;
    display: block;
    overflow: hidden;

    .letter {
      transform-origin: 0 100%;
      display: inline-block;
    }
  }
`;

export const AnimatedText: FC<Props> = ({
  duration,
  each,
  className,
  start = true,
  children,
}) => {
  const wrapper = useRef<HTMLSpanElement>(null);

  const mapLetters = (text: (JSX.Element | string)[]): JSX.Element => (
    <>
      {[...text].map((char, i) => (
        <React.Fragment key={i}>
          {typeof char === "object" ? (
            <span
              {...char.props}
              className={`word-wrapper${
                char.props.className ? ` ${char.props.className}` : ""
              }`}
            >
              {mapLetters((char as JSX.Element).props.children)}
            </span>
          ) : char.length === 1 ? (
            <span
              dangerouslySetInnerHTML={{
                __html: char === " " ? "&nbsp;" : char,
              }}
              className="letter"
            />
          ) : (
            mapLetters([...char])
          )}
        </React.Fragment>
      ))}
    </>
  );

  useEffect(() => {
    if (!wrapper.current) return;

    const letters = wrapper.current.querySelectorAll(".letter");
    const tl = gsap.timeline();

    tl.set(letters, {
      y: "100%",
      x: "0.55em",
      rotateZ: 180,
    });

    if (start) {
      tl.to(letters, {
        y: 0,
        x: 0,
        rotateZ: 0,
        duration: duration || 0.2,
        ease: Expo.easeOut,
        stagger: {
          each: each || 0.02,
        },
      });
    }

    return () => {
      tl.kill();
    };
  }, [start]);

  let content;

  if (typeof children === "object") {
    content = children.map((child, index) => (
      <span key={index}>{mapLetters(child.props.children)}</span>
    ));
  } else if (typeof children === "string") {
    content = ReactHtmlParser(children).map((child: any, index) => (
      <React.Fragment key={index}>
        {typeof child !== "string" ? (
          <span key={index}>{mapLetters(child.props.children)}</span>
        ) : (
          <span style={{ display: "none" }}>&nbsp;</span>
        )}
      </React.Fragment>
    ));
  }

  return (
    <StyledWrapper ref={wrapper} className={className}>
      {content}
    </StyledWrapper>
  );
};

interface Props {
  className?: string;
  duration?: number;
  each?: number;
  start?: boolean;
  children: JSX.Element[] | string;
}
