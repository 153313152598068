import React, { FC, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "@components/atoms/Button";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { breakpoints } from "@theme/breakpoints";
import { Canvas } from "@react-three/fiber";
import medalIcon from "@iconify/icons-noto/sports-medal";
import fireIcon from "@iconify/icons-noto/fire";
import beamingFaceIcon from "@iconify/icons-noto/beaming-face-with-smiling-eyes";
import { Icon } from "@iconify/react/offline";
import { motion } from "framer-motion";
import { useInterval } from "react-use";
import { useProgress } from "@react-three/drei";
import { AnimatedText } from "@components/atoms/AnimatedText";
import { MainContext } from "@context";
import { easeOutExpo } from "@theme/easing";
import Loadable from "@loadable/component";

// @ts-ignore
const HeroRocket = Loadable(() =>
  import("@components/atoms/HeroRocket").then((mod) => mod.HeroRocket)
);

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  overflow: hidden;

  ${breakpoints.tabletMax`
    padding-top: 110px;
    min-height: 1000px;
  `}

  ${breakpoints.phoneMax`
    padding-top: 90px;
    min-height: 750px;
  `}
`;

const StyledInnerWrapper = styled.div`
  max-width: 1300px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-right: 400px;

  @media (max-width: 1280px) {
    width: 90%;
  }

  ${breakpoints.tabletMax`
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-bottom: 90px;
    padding-right: 0;
  `}
`;

const StyledLeftWrapper = styled.div`
  ${breakpoints.tabletMax`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

const StyledHeadline = styled.h1`
  margin: 20px 0 40px;
  max-width: 840px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 700;

  ${breakpoints.laptopMax`
    max-width: 640px;
  `}

  ${breakpoints.tabletMax`
    text-align: center;
  `}
`;

const StyledRocketWrapper = styled(motion.div)`
  position: absolute;
  right: -10%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;

  canvas {
    width: 600px;
    height: 600px;
  }

  ${breakpoints.tabletMax`
    position: static;
    transform: translateY(0);
    margin: -40px 0 -110px;
  `}

  ${breakpoints.phoneMax`
    width: 80%;
    height: 500px;
    margin: -80px 0 -140px;
  `}
`;

const StyledFactWrapper = styled(motion.div)`
  position: relative;
  display: grid;
  overflow: hidden;
`;

const StyledFact = styled(motion.span)`
  display: flex;
  width: 100%;
  grid-area: 1 / 1 / auto / auto;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.washStrong};
  transform: translateY(-100%);
  white-space: nowrap;
  justify-content: flex-start;

  svg {
    margin-left: 10px;
    font-size: 2rem;
  }

  ${breakpoints.tabletMax`
    justify-content: center;
  `}

  ${breakpoints.phoneMax`
    font-size: 1.4rem;
    
    svg {
      font-size: 1.8rem;
      margin-left: 5px;
    }
  `}
`;

const StyledButtonWrapper = styled(motion.div)`
  ${breakpoints.tabletMax`
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom:0;
    left: 0;
  `}
`;

export const Hero: FC<Props> = () => {
  const { t } = useTranslation();
  const [currentText, setCurrentText] = useState(0);
  const { loaded } = useProgress();
  const { setLoading, scroller } = useContext(MainContext);
  const [animateHeadline, setAnimateHeadline] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (loaded) {
      setLoading(false);
      timeout = setTimeout(() => {
        setAnimateHeadline(true);
      }, 700);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [loaded, setLoading]);

  useInterval(() => {
    if (currentText === 2) {
      setCurrentText(0);
    } else {
      setCurrentText((prevState) => prevState + 1);
    }
  }, 4500);

  const ALL_FACTS = [
    ["+500", medalIcon],
    ["98%", beamingFaceIcon],
    ["4", fireIcon],
  ];

  const texts = ALL_FACTS.map(([count, icon], index) => (
    <>
      {/* @ts-ignore */}
      {t(`hero:attributes.${index}`, { count })}
      <Icon icon={icon} />
    </>
  ));

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledLeftWrapper>
          <StyledFactWrapper
            initial={{ y: "-100%", opacity: 0 }}
            animate={loaded ? { y: 0, opacity: 1 } : {}}
            transition={{ delay: 1, duration: 1, ease: easeOutExpo }}
          >
            {texts.map((text, index) => (
              <StyledFact
                key={index}
                initial={
                  currentText === index
                    ? { y: 0, opacity: 1 }
                    : { y: "-100%", opacity: 0 }
                }
                animate={
                  currentText === index
                    ? { y: 0, opacity: 1 }
                    : { y: "-100%", opacity: 0 }
                }
                transition={{ duration: 0.5 }}
              >
                {text}
              </StyledFact>
            ))}
          </StyledFactWrapper>
          <StyledHeadline>
            <AnimatedText start={animateHeadline} duration={0.5}>
              {t("hero:title")}
            </AnimatedText>
          </StyledHeadline>
          <StyledButtonWrapper
            initial={{ opacity: 0, y: "50%" }}
            animate={loaded ? { y: 0, opacity: 1 } : {}}
            transition={{ delay: 1, duration: 1, ease: easeOutExpo }}
          >
            <Button
              secondary
              onClick={() => {
                const el = document.querySelector("#contact");
                if (!el) return;
                scroller?.toElement(el);
              }}
            >
              {t("hero:button")}
            </Button>
          </StyledButtonWrapper>
        </StyledLeftWrapper>

        <StyledRocketWrapper
          initial={{ opacity: 0 }}
          animate={loaded ? { opacity: 1 } : {}}
          transition={{ delay: 0.6, duration: 1 }}
        >
          <Canvas shadows dpr={[1, 2]}>
            <HeroRocket />
          </Canvas>
        </StyledRocketWrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

interface Props {}
