import React, { FC } from "react";
import styled from "styled-components";
import { ImageDataLike, GatsbyImage, getImage } from "gatsby-plugin-image";
import { breakpoints } from "@theme/breakpoints";
import { cssEaseOutExpo, easeOutExpo } from "@theme/easing";
import { motion } from "framer-motion";
import Atropos from "atropos/react";
import { useInView } from "react-intersection-observer";
import { Link } from "gatsby-plugin-react-i18next";
import { Routes } from "@routes";
import { useI18next } from "gatsby-plugin-react-i18next";

const StyledMotionWrapper = styled(motion.div)`
  width: 630px;
  height: 730px;

  ${breakpoints.laptopMax`
    width: 480px;
    height: 580px;
  `};

  @media (max-width: 1150px) {
    width: 430px;
    height: 560px;
  }

  ${breakpoints.tabletMax`
    width: 100%;
    height: 550px;
  `}

  ${breakpoints.phoneMax`
    height: 400px;
  `}
`;

const StyledAtropos = styled(Atropos)`
  width: 630px;
  height: 730px;

  ${breakpoints.laptopMax`
    width: 480px;
    height: 580px;
  `};

  @media (max-width: 1150px) {
    width: 430px;
    height: 560px;
  }

  ${breakpoints.tabletMax`
    width: 100%;
    height: 550px;
  `}

  ${breakpoints.phoneMax`
    height: 400px;
  `}
  
  ${breakpoints.phoneMax`
    //pointer-events: none;
  `}
`;

const StyledWrapper = styled.article`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);

  ${breakpoints.phoneMax`
    border-radius: 20px;
  `}
`;

const StyledImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 1s ${cssEaseOutExpo};

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  ${StyledAtropos}.atropos-active & {
    transform: scale(1.1);
  }
`;

const StyledOverlay = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
`;

const StyledInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 60px 50px;

  ${breakpoints.laptopMax`
    padding: 50px 40px;
  `}

  ${breakpoints.phoneMax`
    padding: 30px;
  `}
`;

const StyledHeadline = styled.h3`
  color: #fff;
  font-size: 3.6rem;
  font-weight: 700;
  margin: 0 0 15px;
  line-height: 1.5;
  max-width: 80%;

  ${breakpoints.laptopMax`
    font-size: 2.4rem;
  `}

  ${breakpoints.phoneMax`
    font-size: 1.8rem;
  `}
`;

const StyledDescription = styled.p`
  color: #ebebeb;
  font-size: 2.4rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.5;
  max-width: 90%;

  ${breakpoints.laptopMax`
    font-size: 1.8rem;
  `}

  ${breakpoints.phoneMax`
    font-size: 1.6rem;
  `}
`;

export const OurProject: FC<Props> = ({
  image,
  title,
  description,
  className,
  slug,
  ...props
}) => {
  const { originalPath } = useI18next();
  const { ref, inView } = useInView({ threshold: 0.05 });
  return (
    <StyledMotionWrapper
      className={className}
      ref={ref}
      initial={{ y: "30%", opacity: 0 }}
      animate={inView && { opacity: 1, y: 0 }}
      transition={{ duration: 2, ease: easeOutExpo }}
    >
      <StyledAtropos
        shadow={false}
        rotateTouch={false}
        activeOffset={20}
        rotateXMax={10}
        rotateYMax={10}
        {...props}
      >
        <Link
          to={`${Routes.RealisedProjects}${slug}/`}
          state={{ from: originalPath }}
        >
          <StyledWrapper>
            <StyledImageWrapper>
              <GatsbyImage
                image={getImage(image)}
                alt={title}
                objectFit="cover"
              />
            </StyledImageWrapper>
            <StyledOverlay />
            <StyledInnerWrapper>
              <StyledHeadline>{title}</StyledHeadline>
              <StyledDescription>{description}</StyledDescription>
            </StyledInnerWrapper>
          </StyledWrapper>
        </Link>
      </StyledAtropos>
    </StyledMotionWrapper>
  );
};

interface Props {
  image: ImageDataLike;
  title: string;
  description: string;
  className?: string;
  slug: string;
}
