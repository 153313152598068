import React, { FC, useRef } from "react";
import styled from "styled-components";
import { BasicTemplate } from "@templates/BasicTemplate";
import { useTranslation } from "react-i18next";
import { Hero } from "@components/molecules/Hero";
import { OurClients } from "@components/atoms/OurClients";
import { breakpoints } from "@theme/breakpoints";
import { ServiceSection } from "@components/molecules/ServiceSection";
import { OurProjectsSection } from "@components/molecules/OurProjectsSection";
import { TechnologiesWeUseSection } from "@components/molecules/TechnologiesWeUseSection";
import { useIntersection } from "react-use";
import { motion } from "framer-motion";
import { easeOutExpo } from "@theme/easing";
import { WhyUsSection } from "@components/molecules/WhyUsSection";
import { ReviewsSection } from "@components/molecules/ReviewsSection";
import { Contact } from "@components/molecules/Contact";
import { graphql } from "gatsby";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { getRealisedProjectSlug } from "@utils/getRealisedProjectSlug";

const StyledHeadline = styled(motion.h2)`
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 1.5;
  width: 90%;
  max-width: 900px;
  text-align: center;
  margin: 40px auto 0;

  ${breakpoints.laptopMax`
    max-width: 750px;
    font-size: 2.4rem;
  `}

  ${breakpoints.phoneMax`
    font-size: 1.8rem;
  `}
`;

const Home: FC<{
  data: Data;
}> = ({ data }) => {
  const { t } = useTranslation("common");
  const ref = useRef(null);
  const intersection = useIntersection(ref, { threshold: 0.4 });

  return (
    <BasicTemplate
      title={t("title")}
      description={t("description")}
      renderHero={Hero}
    >
      <StyledHeadline
        ref={ref}
        initial={{ opacity: 0, y: "100%" }}
        animate={intersection?.isIntersecting && { y: 0, opacity: 1 }}
        transition={{ ease: easeOutExpo, duration: 2.5 }}
      >
        {t("marketing-headline")}
      </StyledHeadline>
      <OurClients />
      <ServiceSection />
      <OurProjectsSection
        projects={data.strapiHomepage.featuredRealisedProjects.map(
          ({ featuredImage, featuredCardText, name, strapi_id }) => ({
            featuredImage: featuredImage,
            featuredText: featuredCardText,
            name: name,
            slug: getRealisedProjectSlug(strapi_id),
          })
        )}
      />
      <TechnologiesWeUseSection />
      <WhyUsSection />
      <ReviewsSection />
      <Contact />
    </BasicTemplate>
  );
};

interface Data {
  strapiHomepage: {
    featuredRealisedProjects: {
      strapi_id: number;
      name: string;
      featuredCardText: string;
      featuredImage: {
        localFile: ChildImageSharp;
      };
    }[];
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    strapiHomepage(locale: { eq: $language }) {
      featuredRealisedProjects {
        strapi_id
        name
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 80
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        featuredCardText
      }
    }
  }
`;

export default Home;
