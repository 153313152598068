import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { stylesWrapper } from "@theme/commonStyles";
import { Service } from "@components/atoms/Service";
import { breakpoints } from "@theme/breakpoints";
import { Button } from "@components/atoms/Button";
import { motion } from "framer-motion";
import { useWindowSize } from "react-use";
import { devices } from "@theme/devices";
import { easeInOutCirc } from "@theme/easing";
import { sumArray } from "@utils/sumArray";
import { graphql, useStaticQuery } from "gatsby";
import { SimpleFileQuery } from "@interfaces/childImageSharp";
import { Routes } from "@routes";

const StyledWrapper = styled.section`
  ${stylesWrapper};
`;

const StyledSectionHeadline = styled(SectionHeadline)`
  margin: 0 0 200px;

  ${breakpoints.laptopMax`
    margin: 0 0 160px;
  `}

  ${breakpoints.phoneMax`
    margin: 0 0 60px;
  `}
`;

const StyledServicesWrapper = styled(motion.div)`
  overflow: hidden;
`;

const StyledService = styled(Service)`
  margin-bottom: 120px;

  ${breakpoints.laptopMax`
    margin-bottom: 60px;
  `};

  :last-of-type {
    margin-bottom: 0;
  }

  ${breakpoints.phoneMax`
    margin-bottom: 40px;
  `}
`;

const StyledButton = styled(Button)`
  margin: 120px auto 0;

  ${breakpoints.laptopMax`
    margin: 90px auto 0;
  `}

  ${breakpoints.phoneMax`
    margin: 60px auto 0;
  `}
`;

const INITIAL = 3;

export const ServiceSection = () => {
  const { t } = useTranslation("service");
  const { file } = useStaticQuery<SimpleFileQuery>(query);
  const [maxServices, setMaxServices] = useState<number>(INITIAL);
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  const [heights, setHeights] = useState<number[]>([]);

  const SERVICES = [
    {
      key: "visualizations",
      video: "/videos/visualizations_*.mp4",
      seeMore: Routes.Visualizations3D,
    },
    {
      key: "websites",
      video: "/videos/websites_*.mp4",
      seeMore: Routes.InvestmentWebsite,
    },
    {
      key: "marketing-campaigns",
      video: "/videos/marketing_campaigns_*.mp4",
      seeMore: Routes.MarketingCampaigns,
    },
    { key: "branding", image: file.childImageSharp },
    {
      key: "animations",
      video: "/videos/animations_*.mp4",
      seeMore: Routes.Animations3D,
    },
    {
      key: "virtual-walk",
      video: "/videos/virtual_tour_*.mp4",
      seeMore: Routes.VirtualTour,
    },
  ];

  const margin =
    width > devices.laptop.max ? 120 : width > devices.phone.max ? 60 : 40;

  const handleButtonClick = () => {
    setMaxServices((prevState) =>
      prevState === INITIAL ? SERVICES.length : INITIAL
    );
  };

  useEffect(() => {
    if (!ref.current) return;

    const children: HTMLDivElement[] = [
      ...(ref.current.childNodes as unknown as Array<ChildNode>),
    ] as HTMLDivElement[];

    setHeights(children.map((child) => child.getBoundingClientRect().height));
  }, [ref, width]);

  return (
    <StyledWrapper>
      <StyledSectionHeadline
        dangerouslySetInnerHTML={{
          __html: t("title"),
        }}
      />

      <StyledServicesWrapper
        transition={{ duration: 1, ease: easeInOutCirc }}
        initial={
          heights.length
            ? {
                maxHeight:
                  sumArray(heights, { from: 0, to: maxServices }) +
                  margin * maxServices -
                  margin,
              }
            : undefined
        }
        animate={
          heights.length
            ? {
                maxHeight:
                  sumArray(heights, { from: 0, to: maxServices }) +
                  margin * maxServices -
                  margin,
              }
            : undefined
        }
        ref={ref}
      >
        {SERVICES.map(({ key, image, video, seeMore }, index) => (
          <StyledService
            title={t(`${key}.title`)}
            subtitle={t(`${key}.subtitle`)}
            description={t(`${key}.description`)}
            image={image ? image.gatsbyImageData : undefined}
            video={video}
            key={key}
            reversed={(index + 1) % 2 === 0}
            seeMore={seeMore}
          />
        ))}
      </StyledServicesWrapper>

      <StyledButton secondary onClick={handleButtonClick}>
        {SERVICES.length !== maxServices ? t("more-button") : t("less-button")}
      </StyledButton>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "branding" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: TRACED_SVG)
      }
    }
  }
`;
