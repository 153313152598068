import React, { FC } from "react";
import styled from "styled-components";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { OurProject } from "@components/atoms/OurProject";
import { breakpoints } from "@theme/breakpoints";
import { useTranslation } from "gatsby-plugin-react-i18next";
import "atropos/css";
import { ChildImageSharp } from "@interfaces/childImageSharp";

const StyledWrapper = styled.section`
  width: 90%;
  max-width: 1400px;
  margin: 200px auto 0;
  position: relative;

  ${breakpoints.laptopMax`
    margin: 150px auto 0;
    max-width: 1100px;
  `}

  ${breakpoints.tabletMax`
    margin: 110px auto 0;
  `};

  ${breakpoints.phoneMax`
    margin: 90px auto 0;
  `};
`;

const StyledHeadline = styled(SectionHeadline)`
  width: 380px;
  z-index: 1;
  line-height: 1.5;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;

  ${breakpoints.tabletMax`
    width: unset;
    position: static;
    margin-bottom: 90px;
  `}

  ${breakpoints.phoneMax`
    margin-bottom: 60px;
  `}
`;

const StyledOurProject = styled(OurProject)`
  :nth-of-type(odd) {
    margin-left: auto;
    margin-top: -100px;
  }

  :nth-of-type(even) {
    margin-right: auto;
    margin-top: -270px;
  }

  :nth-of-type(1) {
    margin-top: 0;
  }

  ${breakpoints.tabletMax`
    :nth-of-type(odd), :nth-of-type(even) {
      margin: 0 0 60px;
    }
    
    
    :last-of-type {
      margin: 0;
    }
  `}

  ${breakpoints.phoneMax`
    :nth-of-type(odd), :nth-of-type(even) {
      margin: 0 0 30px;
    }  
  `};
`;

export const OurProjectsSection: FC<Props> = ({
  secondary,
  headlineAs,
  className,
  projects,
}) => {
  const { t } = useTranslation("our-projects");

  return (
    <StyledWrapper className={className} id="our-projects">
      <StyledHeadline
        dangerouslySetInnerHTML={{
          __html: t(secondary ? "title-secondary" : "title"),
        }}
        forwardedAs={headlineAs as any}
      />
      {projects.map(({ slug, featuredImage, featuredText, name }, index) => (
        <StyledOurProject
          key={index}
          image={featuredImage.localFile.childImageSharp?.gatsbyImageData}
          title={name}
          description={featuredText}
          slug={slug}
        />
      ))}
    </StyledWrapper>
  );
};

interface Props {
  secondary?: true;
  headlineAs?: string;
  projects: {
    name: string;
    featuredText: string;
    featuredImage: {
      localFile: ChildImageSharp;
    };
    slug: string;
  }[];
  className?: string;
}
